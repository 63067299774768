// Poppins - Light
@font-face {
    font-family: 'Poppins_Light';
    src: url('#{$font-path}/poppins/poppins-light.eot');
    src: url('#{$font-path}/poppins/poppins-light.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/poppins/poppins-light.woff') format('woff'),
    url('#{$font-path}/poppins/poppins-light.woff2') format('woff2'),
    url('#{$font-path}/poppins/poppins-light.ttf') format('truetype'),
    url('#{$font-path}/poppins/poppins-light.svg#poppins-light') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Poppins - Regular
@font-face {
    font-family: 'Poppins_Regular';
    src: url('#{$font-path}/poppins/poppins-regular.eot');
    src: url('#{$font-path}/poppins/poppins-regular.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/poppins/poppins-regular.woff') format('woff'),
    url('#{$font-path}/poppins/poppins-regular.woff2') format('woff2'),
    url('#{$font-path}/poppins/poppins-regular.ttf') format('truetype'),
    url('#{$font-path}/poppins/poppins-regular.svg#poppins-regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Poppins - Medium
@font-face {
    font-family: 'Poppins_Medium';
    src: url('#{$font-path}/poppins/poppins-medium.eot');
    src: url('#{$font-path}/poppins/poppins-medium.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/poppins/poppins-medium.woff') format('woff2'),
    url('#{$font-path}/poppins/poppins-medium.woff2') format('woff'),
    url('#{$font-path}/poppins/poppins-medium.ttf') format('truetype'),
    url('#{$font-path}/poppins/poppins-medium.svg#poppins-medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Poppins - Bold
@font-face {
    font-family: 'Poppins_Bold';
    src: url('#{$font-path}/poppins/poppins-bold.eot');
    src: url('#{$font-path}/poppins/poppins-bold.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/poppins/poppins-bold.woff') format('woff2'),
    url('#{$font-path}/poppins/poppins-bold.woff2') format('woff'),
    url('#{$font-path}/poppins/poppins-bold.ttf') format('truetype'),
    url('#{$font-path}/poppins/poppins-bold.svg#poppins-bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Poppins - Black
@font-face {
    font-family: 'Poppins_Black';
    src: url('#{$font-path}/poppins/poppins-black.eot');
    src: url('#{$font-path}/poppins/poppins-black.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/poppins/poppins-black.woff') format('woff2'),
    url('#{$font-path}/poppins/poppins-black.woff2') format('woff'),
    url('#{$font-path}/poppins/poppins-black.ttf') format('truetype'),
    url('#{$font-path}/poppins/poppins-black.svg#poppins-black') format('svg');
    font-weight: normal;
    font-style: normal;
}

////////////////////////////////

// Source Sans Pro - Light
@font-face {
    font-family: 'Source_Sans_Pro_Light';
    src: url('#{$font-path}/source-sans-pro/source-sans-pro-light.eot');
    src: url('#{$font-path}/source-sans-pro/source-sans-pro-light.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-light.woff') format('woff'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-light.woff2') format('woff2'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-light.ttf') format('truetype'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-light.svg#source-sans-pro-light') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Source Sans Pro - Regular
@font-face {
    font-family: 'Source_Sans_Pro_Regular';
    src: url('#{$font-path}/source-sans-pro/source-sans-pro-regular.eot');
    src: url('#{$font-path}/source-sans-pro/source-sans-pro-regular.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-regular.woff') format('woff'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-regular.woff2') format('woff2'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-regular.ttf') format('truetype'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-regular.svg#source-sans-pro-regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Source Sans Pro - Semibold
@font-face {
    font-family: 'Source_Sans_Pro_Semibold';
    src: url('#{$font-path}/source-sans-pro/source-sans-pro-semibold.eot');
    src: url('#{$font-path}/source-sans-pro/source-sans-pro-semibold.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-semibold.woff') format('woff2'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-semibold.woff2') format('woff'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-semibold.ttf') format('truetype'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-semibold.svg#source-sans-pro-semibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Source Sans Pro - Bold
@font-face {
    font-family: 'Source_Sans_Pro_Bold';
    src: url('#{$font-path}/source-sans-pro/source-sans-pro-bold.eot');
    src: url('#{$font-path}/source-sans-pro/source-sans-pro-bold.eot?#iefix') format('embedded-opentype'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-bold.woff') format('woff2'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-bold.woff2') format('woff'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-bold.ttf') format('truetype'),
    url('#{$font-path}/source-sans-pro/source-sans-pro-bold.svg#source-sans-pro-bold') format('svg');
    font-weight: normal;
    font-style: normal;
}


