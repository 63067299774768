.no-margin {
    margin: 0 !important;
}

.block {
    display: block !important;
}

.inline-block {
    display: inline-block !important;
}

.uppercase {
    text-transform: uppercase;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
    max-width: none;
}

.relative,
[data-relative] {
    position: relative;
}

.absolute,
[data-absolute] {
    position: absolute;
}

.padded {
    padding-left: $offset !important;
    padding-right: $offset !important;
}

.no-pt {
    padding-top: 0 !important;
}
.no-pb {
    padding-bottom: 0 !important;
}
.no-pl {
    padding-left: 0 !important;
}
.no-pr {
    padding-right: 0 !important;
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.no-padding {
    padding: 0 !important;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

[data-full-width] {
    @include full-width();
}

[data-view] {
    opacity: 0;
    will-change: auto;
    transition: opacity 1s ease, transform 1s ease !important;
    transition-delay: .1s !important;
    transform: translate3d(0, 2rem, 0);

    @each $attr, $value in $animation-delay-list {
        &[data-view='#{$attr}'] {
            transition-delay: $value !important;
        }
    }

    @each $attr, $values in $animation-direction-list {
        &[view-direction='#{$attr}'] {
            transform: translate3d($values);
        }
    }

    &.in-view,
    &.seen {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.white-bg {
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        width: 120%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: -10%;
        z-index: -1;
        background-color: $white;
    }
}

[data-bg-image] {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.round {
    border-radius: 50%;
}

.bold {
    @include font(700);
}

video {
    max-width: 100%;
}

.spacer {
    position: relative;
    display: block;

    &.s1 {
        height: 1rem;
    }
    &.s15 {
        height: 1.5rem;
    }
    &.s2 {
        height: 2rem;
    }
    &.s3 {
        height: 3rem;
    }
    &.s4 {
        height: 4rem;
    }
}

.color {
    &-white {
        color: $white;
    }
    &-black {
        color: $black;
    }
    &-grey {
        color: $grey;
    }
    &-red {
        color: $coral;
    }
}

.check-mark {
    display: inline-block;
    vertical-align: bottom;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: .5rem;
    position: relative;

    @include breakpoint(xs) {
        width: 1.75rem;
        height: 1.75rem;
    }

    @include breakpoint(sm) {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }

    @include breakpoint(lg) {
        width: 2.25rem;
        height: 2.25rem;
        margin-right: 1.75rem;
    }

    &::before {
        content: '';
        width: 100%;
        height: 50%;
        position: absolute;
        top: 10%;
        left: 0;
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        transform: rotate(-45deg);
    }
}

[data-check-mark] {
    position: relative;
    padding-left: 2.25rem;

    @include breakpoint(xs) {
        padding-left: 2.5rem;
    }

    @include breakpoint(sm) {
        padding-left: 3rem;
    }

    @include breakpoint(lg) {
        padding-left: 4rem;
    }

    &::before {
        content: '';
        width: 1.5rem;
        height: .75rem;
        position: absolute;
        top: 0;
        left: 0;
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        transform: rotate(-45deg);


        @include breakpoint(xs) {
            width: 1.75rem;
            height: .875rem;
        }

        @include breakpoint(sm) {
            width: 2rem;
            height: 1rem;
            top: 10%;
        }

        @include breakpoint(md) {
            top: 20%;
        }

        @include breakpoint(lg) {
            width: 2.25rem;
            height: 1.125rem;
            top: 25%;
        }
    }
}

//Temp Table of Content style
.toc {
    position: relative;
    padding-top: 15vh;

    h1 {
        display: block;
        text-align: center;
    }

    ul {
        li {
            display: block;
            margin-bottom: .75rem;
        }
    }

    a {
        display: inline-block;
        color: initial;

        @include font(400);
    }
}
