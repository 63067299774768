h1,
h2,
h3 {
    line-height: 1.125;
    margin-bottom: 0.5em;
    margin-top: 0;
    color: $dark;
}

h1 {
    font-size: 1.25rem;

    @include breakpoint(sm) {
        font-size: 1.5rem;
    }

    @include breakpoint(lg) {
        font-size: 2.625rem;
    }
}

h2 {
    font-size: 1.25rem;

    @include breakpoint(sm) {
        font-size: 1.5rem;
    }

    @include breakpoint(lg) {
        font-size: 1.625rem;
    }

    &.title {
        font-size: 1.5rem;

        @include breakpoint(sm) {
            font-size: 2rem;
        }

        @include breakpoint(lg) {
            font-size: 2.375rem;
        }
    }
}

h3 {
    font-size: 1.125rem;

    @include breakpoint(sm) {
        font-size: 1.25rem;
    }

    @include breakpoint(lg) {
        font-size: 1.625rem;
    }

    &.title {
        font-size: 1.25rem;

        @include breakpoint(sm) {
            font-size: 1.625rem;
        }

        @include breakpoint(lg) {
            font-size: 2rem;
        }
    }
}

.title {
    @include title(900);
}

.regular {
    @include font(400);
}

.page-title,
.section-title {
    font-size: 2rem;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    line-height: 1.0625em;

    @include title(900);

    @include breakpoint(sm) {
        font-size: 3rem;
    }

    @include breakpoint(lg) {
        font-size: 4rem;
    }

    &::before {
        content: '';
        width: 105%;
        height: 1rem;
        display: block;
        position: absolute;
        left: -2.5%;
        bottom: 4%;
        z-index: -1;

        @include gradient-lr($pink-salmon, $salmon);

        @include breakpoint(sm) {
            height: 1.25rem;
        }

        @include breakpoint(lg) {
            height: 1.5rem;
        }
    }
}

a {
    text-decoration: none;

    &.link {
        position: relative;
        display: inline-block;
        line-height: 1.25em;
        z-index: 1;
        color: $dark;

        @include font(700);
    }
}

strong {
    @include font(700);
}

p {
    font-size: 1rem;
    line-height: 1.5em;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 1.375em;

    @include breakpoint(sm) {
        font-size: 1.125rem;
    }

    @include breakpoint(lg) {
        font-size: 1.25rem;
    }

    @include breakpoint(xl) {
        font-size: 1.375rem;
    }
}
