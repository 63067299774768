header {
    position: relative;
    display: block;
    width: 100%;
    padding: .75rem $offset;
    transition: padding .2s ease;

    @include breakpoint(sm) {
        padding: 1.25rem $offset;
    }

    @include breakpoint(lg) {
        padding: 1.8125rem $offset;
    }

    .shrink {
        min-width: 3rem;
    }

    .columns {
        position: relative;
    }

    .inner-column {
        position: relative;

        @include breakpoint(lg) {
            width: 20rem;
            display: flex;

            &.align-right {
                align-content: flex-end;
            }

            &.align-left {
                align-content: flex-start;
            }
        }
    }

    .social {
        position: relative;
        margin-bottom: 0;

        li {
            display: inline-block;
            vertical-align: top;
            position: relative;
            height: 1.75rem;
            line-height: 1.75rem;
            margin-right: .5rem;
        }

        .icon {
            display: block;
            width: 28px;
            height: 28px;
            position: relative;
            font-size: 1.75rem;
            line-height: 1.75rem;
            color: $dark;
            overflow: hidden;
        }

        span {
            line-height: 2rem;
            display: block;
            font-size: .875rem;
            color: $dark;
            text-transform: uppercase;

            @include font(700);
        }
    }

    .buttons-wrapper {
        a {
            font-size: .875rem;
            display: inline-block;
            position: relative;
            text-transform: uppercase;
            line-height: 1.625rem;
            padding: .125rem .875rem 0;
            letter-spacing: -.25px;

            &.button {
                width: auto;
                min-width: 0;
            }
        }
    }
}
