.sign-up,
.sign-in {
    .buttons-wrap {
        display: block;
        position: relative;
        margin-bottom: 1.5rem;

        & > * {
            display: inline-block;
            vertical-align: middle;
        }

        & > span {
            margin: .5rem 1rem;
            display: block;
            text-align: center;

            @include breakpoint(sm) {
                display: inline-block;
            }
        }

        .button {
            text-transform: uppercase;
            font-size: .875rem;
            line-height: 1em;

            span {
                display: inline-block;
                vertical-align: middle;
                font-size: 1rem;
                position: relative;
                top: -1px;
            }
        }
    }

    p {
        font-size: 1.125rem;

        @include breakpoint(sm) {
            font-size: 1.125rem;
        }

        @include breakpoint(lg) {
            font-size: 1.125rem;
        }

        @include breakpoint(xl) {
            font-size: 1.125rem;
        }

        a {
            display: inline-block;
            color: $dark;
            font-size: .8125rem;
            text-transform: uppercase;

            @include font(700);
        }
    }
}
