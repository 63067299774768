* {
    box-sizing: border-box;
}

html {
    font-size: 100%;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

body {
    background-color: $white;
    font-size: 100%;
    position: relative;
    padding: 0;
    margin: 0;
    line-height: 1.5;
    color: $black;
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;

    @include font(300);
}
