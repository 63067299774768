.address-block {
    display: block;
    position: relative;
    margin-bottom: 2.25rem;

    strong,
    span {
        display: block;
    }

    strong {
        font-size: 1rem;
        line-height: 1;
        margin-bottom: 1.25em;

        @include title(700);

        @include breakpoint(sm) {
            font-size: 1.0625rem;
        }

        @include breakpoint(lg) {
            font-size: 1.125rem;
        }
    }

    span {
        color: $gray;
        font-size: 1rem;
        line-height: 1.375;

        @include font(400);

        a {
            color: $gray;
        }

        @include breakpoint(sm) {
            font-size: 1.125rem;
        }

        @include breakpoint(lg) {
            font-size: 1.375rem;
        }
    }
}
