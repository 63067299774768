.sign-in-form,
.sign-up-form {
    position: relative;

    .buttons-wrap {
        display: block;
        position: relative;
        margin-bottom: 1.5rem;

        & > * {
            display: inline-block;
            vertical-align: middle;
        }

        & > span {
            margin: .5rem 1rem;
            display: block;
            text-align: center;

            @include breakpoint(sm) {
                display: inline-block;
            }
        }

        .button {
            text-transform: uppercase;
            font-size: .875rem;
            line-height: 1em;

            span {
                display: inline-block;
                vertical-align: middle;
                font-size: 1rem;
                position: relative;
                top: -1px;
            }
        }
    }

    .link {
        display: inline-block;
        color: $dark;
        font-size: .8125rem;
        line-height: 1;
        text-transform: uppercase;

        @include font(700);

        @include breakpoint(sm) {
            font-size: .875rem;
        }

        &.forgot-password {
            top: .125rem;
        }
    }
}
