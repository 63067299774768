.sponsor-card {
    position: relative;
    display: inline-block;
    background-color: $white;
    padding: 1.375rem;
    border-radius: 0 1.25rem 1.25rem;
    box-shadow: 0 0 1rem transparentize($dark, .9);
    margin-bottom: 1.5rem;

    @include breakpoint(sm) {
        padding: 1.875rem;
        border-radius: 0 1.875rem 1.875rem;
        margin-bottom: 2.5rem;
    }

    @include breakpoint(lg) {
        padding: 2.5rem;
        border-radius: 0 2.5rem 2.5rem;
        margin-bottom: 3.5rem;
        max-width: 500px;
    }

    img {
        display: block;
        max-width: 55%;
    }
}
