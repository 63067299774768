.profile-container {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 5;

    .avatar {
        display: block;
        width: 46px;
        height: 46px;
        border-radius: 50%;
    }
}

.wrapper-dropdown {
    position: relative;
    cursor: pointer;
    z-index: 2;
    margin-right: 1rem;
    outline: none;

    & > span {
        font-size: .875rem;
        text-transform: uppercase;
        display: block;
        line-height: 1.5;
        padding: .25rem 0 0 1.25rem;
        overflow: hidden;

        @include font(700);

        &::before {
            content: '';
            width: .4375rem;
            height: .4375rem;
            display: block;
            border-left: 1px solid $dark;
            border-bottom: 1px solid $dark;
            position: absolute;
            top: 45%;
            left: .25rem;
            transform: translateY(-50%) rotate(-45deg);
            transition: all .25s ease;
        }
    }

    .dropdown {
        position: absolute;
        top: 2.5rem;
        left: -2.0625rem;
        width: 220px;
        background: $white;
        padding-top: .5rem;
        opacity: 0;
        visibility: hidden;
        transform: translateY(1rem);
        border: 1px solid $porcelain;
        box-shadow: 0 0 1rem transparentize($black, .95);
        will-change: auto;

        @include opacity(.3s);

        &::after {
            content: '';
            width: 0;
            height: 0;
            border: 0 solid transparent;
            border-right-width: .625rem;
            border-left-width: .625rem;
            border-bottom: .625rem solid $white;
            position: absolute;
            bottom: 100%;
            left: 1.875rem;
        }

        &::before {
            content: '';
            width: 0;
            height: 0;
            border: 0 solid transparent;
            border-right-width: .625rem;
            border-left-width: .625rem;
            border-bottom: .625rem solid darken($porcelain, 5%);
            position: absolute;
            bottom: calc(100% + 1px);
            left: 1.875rem;
        }

        li {
            display: block;
            position: relative;
            padding: 0;
            background-color: $white;
            border-bottom: 1px solid darken($porcelain, 5%);

            &:last-of-type {
                border-bottom: 0;
            }

            &:hover,
            &.active {
                background-color: $porcelain;
            }

            &.active {
                a {
                    color: $dark;
                }

                &::after {
                    content: '';
                    width: .625rem;
                    height: .375rem;
                    display: block;
                    position: absolute;
                    border-bottom: 2px solid $dark;
                    border-left: 2px solid $dark;
                    transform: rotate(-45deg);
                    top: 40%;
                    right: .5rem;
                }
            }

            a {
                display: block;
                padding: .875rem 1rem .75rem;
                text-transform: uppercase;
                font-size: .875rem;
                position: relative;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                line-height: 1;
                color: lighten($gray, 5%);
                transition: all .25s ease;

                @include font(700);

                img {
                    display: inline-block;
                    vertical-align: top;
                    margin-top: -.125rem;
                    position: relative;
                    margin-right: .375em;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                }
            }

            &.account-setings {
                text-align: center;

                a {
                    color: $dark;
                    display: inline-block;
                    vertical-align: top;
                    padding: 1.375rem 1rem 1.125rem;

                    .icon {
                        font-size: 1rem;
                        line-height: .75;
                        height: 1em;

                        &::before {
                            font-size: 1rem;
                            line-height: .75;
                            margin-right: .125em;
                            display: inline-block;
                            vertical-align: top;
                            position: relative;
                        }
                    }
                }
            }

            &.log-out {
                text-align: center;

                a {
                    color: $pink;
                    display: inline-block;
                    vertical-align: top;
                    padding: 1.375rem 1rem 1.125rem;

                    .icon {
                        font-size: 1rem;
                        line-height: .75;
                        height: 1em;

                        &::before {
                            font-size: 1rem;
                            line-height: .75;
                            margin-right: .125em;
                            display: inline-block;
                            vertical-align: top;
                            position: relative;
                        }
                    }
                }
            }
        }
    }

    &.active {
        & > span {
            &::before {
                top: 60%;
                transform: translateY(-50%) rotate(135deg);
            }
        }

        .dropdown {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }
}
