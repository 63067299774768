.lightbox {
    border: 0;
    box-shadow: none;
    display: inline-block;
    position: relative;
    text-decoration: none;
}

.lightbox-zoom {
    background: #fff;
    bottom: 0;
    color: #2727a4;
    line-height: 1;
    display: none;
    padding: .306em .5em;
    position: absolute;
    right: 0
}

.lightbox-no-scroll {
    overflow-y: hidden;
}

/* Overlay */
.lightbox-overlay {
    background: rgba(17, 17, 17, .8);
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 32;
    overflow: hidden;
}

.lightbox-overlay *,
.lightbox-overlay *::before,
.lightbox-overlay *::after {
    box-sizing: inherit;
}

.lightbox-overlay[aria-hidden="true"] {
    display: none;
}

/* Slider */
.lightbox-slider {
    -webkit-transition: left .3s ease, -webkit-transform .3s ease;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: left .3s ease, -webkit-transform .3s ease;
    transition: left .3s ease, transform .3s ease, -webkit-transform .3s ease;
    transition: left .3s ease, transform .3s ease;
    white-space: nowrap;
}

/* Content */
.lightbox-content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
}

.lightbox-content figure {
    position: relative;
    margin: 0 auto;
}

.lightbox-content img {
    display: block;
    height: auto;
    max-height: 90vh;
    max-width: 90vw;
    width: auto;
}

.lightbox-content figcaption {
    background: rgba(17, 17, 17, .8);
    bottom: 0;
    color: #fff;
    display: block;
    left: 0;
    padding: .25em .5em;
    position: absolute;
    width: 100%;
}

.lightbox-overlay button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    background: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
    height: 2.5rem;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 2.5rem;
}

.lightbox-overlay button:disabled {
    cursor: default;
    opacity: .5;
}

.lightbox-overlay button[aria-label="Previous"],
.lightbox-overlay button[aria-label="Next"] {
    top: 50%;
    top: calc(50% - (2.5rem / 2));
    color: transparent;
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        position: absolute;
        top: 0;
    }
}

.lightbox-overlay button[aria-label="Previous"] {
    left: 5%;

    &::before {
        left: .875rem;
        transform: rotate(45deg);
    }
}

.lightbox-overlay button[aria-label="Next"] {
    right: 5%;

    &::before {
        right: .875rem;
        transform: rotate(-135deg);
    }
}

.lightbox-overlay button[aria-label="Close"] {
    top: 1rem;
    right: 5%;
    width: 1rem;
    height: 1rem;
    font-size: 1rem;
    line-height: 1;
    color: transparent;
    font-family: Arial, sans-serif;

    &::before,
    &::after {
        content: '';
        width: 2px;
        height: 150%;
        background-color: #fff;
        position: absolute;
        top: -25%;
        left: 50%;
    }

    &::before {
        transform: rotate(-45deg);
    }

    &::after {
        transform: rotate(45deg);
    }

    @media (min-width: 640px) {
        top: 1.5rem;
        font-size: 1.125rem;
        width: 1.125rem;
        height: 1.125rem;
    }

    @media (min-width: 1024px) {
        top: 2.75rem;
        font-size: 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
    }
}

.lightbox-counter {
    background: transparent;
    color: #fff;
    letter-spacing: 2px;
    font-size: 1rem;
    line-height: 1;
    left: 5%;
    position: absolute;
    top: 1rem;
    font-family: Arial, sans-serif;

    @media (min-width: 640px) {
        top: 1.5rem;
        font-size: 1.125rem;
    }

    @media (min-width: 1024px) {
        top: 2.75rem;
        font-size: 1.25rem;
    }
}

.lightbox-loader {
    width: 5em;
    height: 5em;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2.5em;
    margin-left: -2.5em;
}

.lightbox-loader::before {
    -webkit-animation: spin 1s infinite;
    animation: spin 1s infinite;
    border-radius: 100%;
    border: .5em solid #9f9f9f;
    border-top-color: #2727a4;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
