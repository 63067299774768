section {
    position: relative;
    display: block;
    padding: 1rem 0;

    &.reduced-pb {
        padding-bottom: .5rem;
    }
    &.reduced-pt {
        padding-top: .5rem;
    }

    &.double-pb {
        padding-bottom: 2rem;
    }
    &.double-pt {
        padding-top: 2rem;
    }

    @include breakpoint(xs) {
        padding: 1.5rem 0;

        &.reduced-pb {
            padding-bottom: .75rem;
        }
        &.reduced-pt {
            padding-top: .75rem;
        }

        &.double-pb {
            padding-bottom: 3rem;
        }
        &.double-pt {
            padding-top: 3rem;
        }
    }

    @include breakpoint(sm) {
        padding: 2.5rem 0;

        &.reduced-pb {
            padding-bottom: 1.25rem;
        }
        &.reduced-pt {
            padding-top: 1.25rem;
        }

        &.double-pb {
            padding-bottom: 5rem;
        }
        &.double-pt {
            padding-top: 5rem;
        }
    }

    @include breakpoint(lg) {
        padding: 3.5rem 0;

        &.reduced-pb {
            padding-bottom: 1.75rem;
        }
        &.reduced-pt {
            padding-top: 1.75rem;
        }

        &.double-pb {
            padding-bottom: 7rem;
        }
        &.double-pt {
            padding-top: 7rem;
        }
    }
}
