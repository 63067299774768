.related-products {
    position: relative;
    background-color: $white;

    @include breakpoint(up-to-sm) {
        width: calc(100% + 4vw);
        margin-left: -2vw;
    }

    &::after {
        content: '';
        width: 120%;
        height: 45%;
        display: block;
        position: absolute;
        top: 4.5rem;
        left: -10%;
        z-index: -1;

        @include gradient-lr($black, $tundora);

        @include breakpoint(sm) {
            top: 6rem;
            height: 40%;
        }

        @include breakpoint(lg) {
            top: 8.5rem;
            height: 45%;
        }
    }

    .scrollable-container {
        position: relative;
        width: 100%;
        display: block;
        overflow: auto;

        @include breakpoint(lg) {
            overflow: visible;
        }

        .scrollable {
            min-width: 800px;
        }
    }

    .image-container {
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        .item-overlay,
        .offer-desc {
            @include breakpoint(up-to-sm) {
                display: none;
            }
        }
    }
}
