.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.05);
    z-index: 20;

    @include opacity(.25s);

    &.active {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }

    .modal-content {
        position: absolute;
        background-color: $white;
        padding: 2.25rem .25rem 1rem;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        @include breakpoint(sm) {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 2.75rem .75rem 1.5rem;
            width: 95%;
            max-height: 96%;
            height: auto;
            overflow: auto;
            min-width: 18.75rem;
            max-width: 68.75rem;
        }

        @include breakpoint(lg) {
            padding: 3rem 1.25rem 2rem;
        }
    }

    .modal-inner-content {
        position: relative;
        display: block;
    }

    .modal-close {
        position: absolute;
        display: block;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
        width: 1rem;
        height: 1rem;
        font-size: 1rem;
        line-height: 1;
        top: 1rem;
        right: 1rem;
        z-index: 1;

        @include breakpoint(sm) {
            width: 1.25rem;
            height: 1.25rem;
            font-size: 1.25rem;
            line-height: 1.25;
            top: 1.25rem;
            right: 1.25rem;
        }
    }

    .title {
        position: relative;
        display: inline-block;
        z-index: 1;

        &::before {
            content: '';
            width: 105%;
            height: .4375em;
            display: block;
            position: absolute;
            left: -2.5%;
            bottom: 4%;
            z-index: -1;

            @include gradient-lr($pink-salmon, $salmon);
        }
    }

    p {
        color: $grey;
        line-height: 1.25;
        display: block;

        @include font(400);
    }
}

