@font-face {
    font-family: "iconfont";
    src: url('../fonts/icons/iconfont.eot');
    src: url('../fonts/icons/iconfont.eot?#iefix') format('eot'),
    url('../fonts/icons/iconfont.woff') format('woff'),
    url('../fonts/icons/iconfont.ttf') format('truetype'),
    url('../fonts/icons/iconfont.svg#iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon:before {
     display: inline-block;
     font-family: "iconfont", serif;
     font-style: normal;
     font-weight: normal;
     line-height: 1;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
}

.icon-archive:before { content: "\EA01" }
.icon-arrow-left:before { content: "\EA02" }
.icon-avatar:before { content: "\EA03" }
.icon-calendar:before { content: "\EA04" }
.icon-chat:before { content: "\EA05" }
.icon-checkmark:before { content: "\EA06" }
.icon-chevron-down:before { content: "\EA07" }
.icon-chevron-left:before { content: "\EA08" }
.icon-chevron-right:before { content: "\EA09" }
.icon-chevron-up:before { content: "\EA0A" }
.icon-close:before { content: "\EA0B" }
.icon-description:before { content: "\EA0C" }
.icon-diamond:before { content: "\EA0D" }
.icon-facebook-alt:before { content: "\EA0E" }
.icon-facebook:before { content: "\EA0F" }
.icon-heart:before { content: "\EA10" }
.icon-image:before { content: "\EA11" }
.icon-info:before { content: "\EA12" }
.icon-instagram:before { content: "\EA13" }
.icon-logo:before { content: "\EA14" }
.icon-logout:before { content: "\EA15" }
.icon-package:before { content: "\EA16" }
.icon-picture:before { content: "\EA17" }
.icon-plus:before { content: "\EA18" }
.icon-present:before { content: "\EA19" }
.icon-rules:before { content: "\EA1A" }
.icon-settings:before { content: "\EA1B" }
.icon-snapchat:before { content: "\EA1C" }
.icon-timer:before { content: "\EA1D" }
.icon-twitter:before { content: "\EA1E" }
.icon-youtube:before { content: "\EA1F" }
