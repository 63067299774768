.badge {
    display: inline-block;
    vertical-align: top;
    position: relative;
    font-size: .75rem;
    line-height: 1em;
    height: 1.5rem;
    white-space: nowrap;
    padding: .5rem .9375rem .425rem 2rem;
    border-radius: 1rem;
    color: $white;
    text-transform: uppercase;
    margin: 0 1rem .5rem 0;

    @include font(600);

    &:before {
        display: block;
        font-size: .875rem;
        width: .875rem;
        height: .875rem;
        position: absolute;
        left: .625rem;
        top: 50%;
        transform: translateY(-50%);
        color: $white;
    }

    &.type-special {
        background-color: $pink;
    }

    &.type-ended {
        @include gradient-lr($black, $tundora);
    }

    &.type-new-offer {
        @include gradient-lr($violet, $plum);
    }

    &.type-last-minute {
        @include gradient-lr($jaffa, $orange);
    }
}
