//Breakpoints
@mixin breakpoint($width) {

    @if ($width == xs) {
        @media (min-width: $xs) {
            @content
        }
    }
    @if ($width == xs-only) {
        @media (max-width: $xs - 1) {
            @content
        }
    }
    @if ($width == sm) {
        @media (min-width: $sm) {
            @content
        }
    }
    @if ($width == up-to-sm) {
        @media (max-width: $sm - 1) {
            @content
        }
    }
    @if ($width == md) {
        @media (min-width: $md) {
            @content
        }
    }
    @if ($width == up-to-md) {
        @media (max-width: $md - 1) {
            @content
        }
    }
    @if ($width == lg) {
        @media (min-width: $lg) {
            @content
        }
    }
    @if ($width == up-to-lg) {
        @media (max-width: $lg - 1) {
            @content
        }
    }
    @if ($width == xl) {
        @media (min-width: $xl) {
            @content
        }
    }
    @if ($width == portrait) {
        @media screen and (orientation: portrait) {
            @content
        }
    }
    @if ($width == landscape) {
        @media screen and (orientation: landscape) {
            @content
        }
    }
}

//Font mixin
@mixin font($type: 300) {
    @if $type == 300 {
        font-family: 'Source_Sans_Pro_Light', sans-serif;
        font-weight: 300;
    }

    @if $type == 400 {
        font-family: 'Source_Sans_Pro_Regular', sans-serif;
        font-weight: 400;
    }

    @if $type == 600 {
        font-family: 'Source_Sans_Pro_Semibold', sans-serif;
        font-weight: 600;
    }

    @if $type == 700 {
        font-family: 'Source_Sans_Pro_Bold', sans-serif;
        font-weight: 700;
    }
}

@mixin title($type: 300) {
    @if $type == 300 {
        font-family: 'Poppins_Light', sans-serif;
        font-weight: 300;
    }

    @if $type == 400 {
        font-family: 'Poppins_Regular', sans-serif;
        font-weight: 400;
    }

    @if $type == 600 {
        font-family: 'Poppins_Semibold', sans-serif;
        font-weight: 600;
    }

    @if $type == 700 {
        font-family: 'Poppins_Bold', sans-serif;
        font-weight: 700;
    }

    @if $type == 900 {
        font-family: 'Poppins_Black', sans-serif;
        font-weight: 900;
    }
}



@mixin full-width {
    width: calc(100% + 5vw * 2);
    margin: 0 -5vw;
}

// Opacity transition
@mixin opacity ($t) {
    transition: opacity $t ease-in-out, visibility $t ease-in-out, transform $t ease-in-out;
}

// Gradient mixins
@mixin gradient-lr($c1, $c2) {
    background: linear-gradient(to right, $c1 0%, $c2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$c1', endColorstr='$c2',GradientType=1 );
}

// Gradient mixins
@mixin gradient-lr3($c1, $c2, $c3) {
    background: linear-gradient(to right, $c1 0%, $c2 50%, $c3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$c1', endColorstr='$c3',GradientType=1 );
}

//@mixin gradient-rl {
//    background: linear-gradient(to right, $blue 0%, $violet 50%, $salmon 100%);
//    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue', endColorstr='$salmon',GradientType=1 );
//}
//@mixin gradient-tb {
//    background: linear-gradient(to bottom, $salmon 0%, $violet 50%, $blue 100%);
//    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$salmon', endColorstr='$blue',GradientType=0 );
//}
//@mixin gradient-bt {
//    background: linear-gradient(to bottom, $blue 0%, $violet 50%, $salmon 100%);
//    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue', endColorstr='$salmon',GradientType=0 );
//}
