.button {
    position: relative;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    font-size: .875rem;
    line-height: 1em;
    padding: .9375rem 1.25rem .8125rem;
    transition: all .25s ease;
    background-color: $black;
    color: $white;
    min-width: 180px;
    width: auto;
    letter-spacing: 1px;

    @include breakpoint(sm) {
        font-size: .9375rem;
        padding: .875rem 1.25rem .8125rem;
    }

    @include breakpoint(lg) {
        font-size: 1rem;
        padding: .875rem 1.25rem .75rem;
    }

    @include font(700);

    &.wide {
        width: 100%;
        padding-left: 1.25rem;
        padding-right: 1.25rem;

        @include breakpoint(md) {
            padding-left: .75rem;
            padding-right: .75rem;
        }
    }

    &.hollow {
        background-color: transparent;
        color: $white;
        border: 2px solid $white;
    }

    &.round {
        border-radius: 2rem;
    }

    &.large {
        width: auto;
        padding: 1rem 1.75rem .875rem;

        @include breakpoint(md) {
            padding: 1.25rem 2.5rem 1rem;
        }
    }

    &.facebook {
        background-color: $facebook;
        color: $white;
        height: 42px;

        &:hover {
            background-color: darken($facebook, 5%);
        }
    }

    &.gradient-type-1 {
        text-transform: uppercase;

        @include gradient-lr($black, $tundora);

        &:hover {
            @include gradient-lr($black, $black);
        }
    }

    &.gradient-type-2 {
        text-transform: uppercase;

        @include gradient-lr($violet, $plum);

        &:hover {
            @include gradient-lr($violet, $violet);
        }
    }

    &.gradient-type-3 {
        text-transform: uppercase;

        @include gradient-lr($jaffa, $orange);

        &:hover {
            @include gradient-lr($jaffa, $jaffa);
        }
    }

    &.gradient-type-4 {
        text-transform: uppercase;

        @include gradient-lr($pink-salmon, $salmon);

        &:hover {
            @include gradient-lr($pink-salmon, $pink-salmon);
        }
    }
}

.block-button {
    display: block;
    position: relative;
    margin-bottom: 1.5rem;
    border: 1px solid darken($porcelain, 5%);
    padding: 1rem;
    box-shadow: 0 0 0 1px $white inset;
    transition: all .35s ease;

    @include breakpoint(sm) {
        padding: 1.25rem;
    }

    @include breakpoint(lg) {
        padding: 1.875rem 1.5rem;
    }

    .columns {
        &:first-of-type {
            min-width: 4.25rem;

            @include breakpoint(sm) {
                min-width: 4.5rem;
            }

            @include breakpoint(lg) {
                min-width: 5rem;
            }
        }
    }

    strong,
    span {
        display: block;
        font-size: 1rem;
        line-height: 1.125em;

        @include breakpoint(sm) {
            font-size: 1.0625rem;
        }

        @include breakpoint(lg) {
            font-size: 1.125rem;
        }
    }

    strong {
        color: $dark;

        @include title(700);
    }

    span {
        color: $grey;

        @include title(400);
    }

    .icon {
        display: block;
        width: 2.25rem;
        height: 2.25rem;

        @include breakpoint(sm) {
            width: 2.5rem;
            height: 2.5rem;
        }

        @include breakpoint(lg) {
            width: 3rem;
            height: 3rem;
        }
    }

    .status {
        font-size: .75rem;
        line-height: 1.25;
        display: block;
        text-transform: uppercase;

        @include title(700);

        &.connected {
            color: $forest;
        }
        &.suspended {
            color: $pink;
        }
    }

    .action {
        font-size: .875rem;
        line-height: 1;
        display: block;
        text-transform: uppercase;
        color: $dark;

        @include title(700);
    }
}

a.block-button {
    &:hover,
    &.active {
        border-color: $pink;
        box-shadow: 0 0 0 1px $pink inset;
    }
}

.back-button {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem;
    position: absolute;
    left: .75rem;
    top: .1875rem;
    color: $dark;

    @include breakpoint(sm) {
        width: 2rem;
        height: 2rem;
        font-size: 2rem;
        top: .375rem;
    }

    @include breakpoint(lg) {
        width: 3rem;
        height: 3rem;
        font-size: 3rem;
        top: .375rem;
    }

    &::before {
        font-size: inherit;
    }

    // Main
    main & {
        @include breakpoint(up-to-lg) {
            display: none;
        }
    }

    // Header
    header & {
        position: relative;
        top: 0;
        left: 0;
        opacity: 1 !important;
        transform: translateY(0) !important;

        @include breakpoint(lg) {
            display: none;
        }
    }
}


.tag {
    display: inline-block;
    vertical-align: top;
    position: relative;
    white-space: nowrap;
    border-radius: 2rem;
    font-size: 1rem;
    line-height: 1;
    color: $grey;
    border: 1px solid darken($porcelain, 5%);
    padding: .375rem 1.25rem .25rem;
    margin: 0 .375rem .625rem 0;
    transition: all .25s ease;

    @include font(400);

    @include breakpoint(sm) {
        font-size: 1.125rem;
        padding: .375rem 1.25rem .25rem;
        margin: 0 .375rem .625rem 0;
    }

    @include breakpoint(lg) {
        font-size: 1.375rem;
        padding: .3125rem 1.25rem;
        margin: 0 .5rem 1rem 0;
    }

    &:hover {
        color: $dark;
        border: 1px solid $iron;
    }
}
