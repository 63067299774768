.tabbed-content {
    position: relative;
    display: block;
    width: 100%;

    .tabs-links {
        position: relative;
        display: block;
        background-color: $amour;
        white-space: nowrap;
        z-index: 1;

        &::before {
            content: '';
            width: 120%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: -10%;
            z-index: -1;
            background-color: $amour;
        }

        .inner-scroll {
            display: block;
            width: calc(100% + 4vw);
            margin-left: -2vw;
            position: relative;
            overflow: auto;
        }

        .tab {
            display: inline-block;
            vertical-align: top;
            font-size: .75rem;
            line-height: 1;
            text-transform: uppercase;
            color: $dark;
            cursor: pointer;
            position: relative;
            padding: 1.75rem 2.75rem 1.75rem 4.25rem;
            background-color: transparent;

            & ~ .tab {
                margin-left: -3px;
            }

            @include font(700);

            &::before {
                content: '';
                width: 1px;
                height: 70%;
                display: block;
                position: absolute;
                top: 15%;
                right: 0;
                background-color: $iron;
            }

            @include breakpoint(sm) {
                font-size: .8125rem;
                padding: 2rem 3.75rem 2rem 5.75rem;
            }

            @include breakpoint(lg) {
                font-size: .875rem;
                padding: 2.75rem 4.75rem 2.75rem 7.25rem;
            }

            &.active {
                color: $pink;
                background-color: $white;
            }
        }

        .icon {
            display: block;
            position: absolute;
            top: 50%;
            left: 1.125rem;
            height: 2rem;
            font-size: 2rem;
            line-height: 1;
            overflow: hidden;
            transform: translateY(-55%);

            @include breakpoint(sm) {
                left: 1.75rem;
                height: 2.5rem;
                font-size: 2.5rem;
            }

            @include breakpoint(lg) {
                left: 2.5rem;
                height: 3rem;
                font-size: 3rem;
            }

            &::before {
                font-size: 2rem;
                line-height: 1;

                @include breakpoint(sm) {
                    font-size: 2.5rem;
                }

                @include breakpoint(lg) {
                    font-size: 3rem;
                }
            }
        }

        &:last-of-type {
            &::before {
                display: none;
            }
        }
    }

    .tabs-content {
        display: block;
        position: relative;
        background-color: $white;
        padding: 2rem 0;

        .tab-panel {
            position: relative;
            display: none;

            &.active {
                display: block;
            }
        }
    }
}
