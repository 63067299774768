.order-process {

    ul {
        display: block;
        position: relative;

        @include breakpoint(sm) {
            display: flex;
            align-items: center;
        }

        li {
            display: block;
            position: relative;
            margin-bottom: 1.25rem;

            @include breakpoint(sm) {
                width: 33.333%;
                padding: 0 1rem;

                &:after {
                    content: '';
                    width: .875rem;
                    height: .875rem;
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    border-right: 2px solid $iron;
                    border-bottom: 2px solid $iron;
                    transform: translateY(-50%) rotate(-45deg);
                }

                &:last-child {
                    padding-right: 0;

                    &:after {
                        display: none;
                    }
                }
            }

            @include breakpoint(lg) {
                padding: 0 2rem;
            }

            span {
                display: inline-block;
                vertical-align: top;
                line-height: 1.375em;
                font-size: 1rem;
                letter-spacing: -.5px;
                padding-left: 3.75rem;
                position: relative;

                @include title(700);

                @include breakpoint(sm) {
                    font-size: .875rem;
                    margin-right: 1rem;
                }

                @include breakpoint(md) {
                    font-size: 1rem;
                }

                @include breakpoint(lg) {
                    padding-left: 4.5rem;
                    font-size: 1.125rem;
                }

                &::before {
                    font-size: 2.125rem;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);

                    @include breakpoint(lg) {
                        font-size: 3rem;
                    }
                }
            }
        }
    }
}
