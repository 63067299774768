.logo {
    display: block;
    width: 117px;
    height: 32px;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    @include breakpoint(sm) {
        width: 154px;
        height: 42px;
    }

    @include breakpoint(lg) {
        width: 190px;
        height: 52px;
    }

    svg {
        display: block;
        width: 100%;
        pointer-events: none;
    }
}
