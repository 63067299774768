.profile-card {
    position: relative;
    display: block;
    margin-bottom: 1.5rem;

    &.boxed {
        padding: 1.75rem 1.5rem 1.5rem;
        border: 1px solid darken($porcelain, 5%);

        @include breakpoint(sm) {
            padding: 2.75rem 2.5rem 2.25rem;
        }

        @include breakpoint(lg) {
            padding: 3.75rem 16.6666% 3rem;
        }
    }

    &.highlighted {
        background-color: $pippin;
        border: 1px solid $salmon;
        padding: 1rem;

        @include breakpoint(sm) {
            padding: 1.25rem 2.5rem;
        }

        @include breakpoint(lg) {
            padding: 1.5rem 4rem;
        }
    }

    .avatar {
        display: block;
        margin-bottom: 1.5rem;
        width: 3.875rem;
        height: 3.875rem;
        border: 1px solid darken($porcelain, 5%);

        @include breakpoint(sm) {
            margin-bottom: 2rem;
            width: 4.25rem;
            height: 4.25rem;
        }

        @include breakpoint(lg) {
            margin-bottom: 2.5rem;
            width: 5.5rem;
            height: 5.5rem;
        }
    }

    .nickname {
        display: block;
        font-size: 1.625rem;
        line-height: 1.125em;
        margin-bottom: .75rem;
        text-transform: uppercase;

        @include title(900);

        @include breakpoint(sm) {
            font-size: 1.875rem;
        }

        @include breakpoint(lg) {
            font-size: 2.375rem;
        }
    }

    .registration-date,
    .latest-campaigns-list,
    .profile-data {
        display: block;
        font-size: 1rem;
        color: $grey;

        @include font(400);

        @include breakpoint(sm) {
            font-size: 1.125rem;
        }

        @include breakpoint(lg) {
            font-size: 1.375rem;
        }

        b {
            color: $dark;

            @include font(400);
        }
    }

    .profile-data {
        line-height: 1.125;
        margin-bottom: .75rem;
        margin-right: 1rem;
    }

    .registration-date {
        margin-bottom: 2rem;
    }

    .latest-campaigns-list {
        margin-bottom: 1.5rem;

        @include breakpoint(sm) {
            margin-bottom: 2rem;
        }

        @include breakpoint(lg) {
            margin-bottom: 2.75rem;
        }
    }

    .campaigns-count,
    .heading {
        display: block;
        font-size: 1.5rem;
        line-height: 1.125em;
        margin-bottom: .75rem;
        color: $dark;

        @include title(300);

        @include breakpoint(sm) {
            font-size: 1.875rem;
        }

        @include breakpoint(lg) {
            font-size: 2.375rem;
        }
    }

    .heading {
        &.bold {
            @include title(900);
        }
    }

    .action-link {
        font-size: .875rem;
        display: inline-block;
        text-transform: uppercase;
        color: $dark;

        @include title(700);
    }

    .avatar-editor {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 1.5rem;

        @include breakpoint(sm) {
            margin-bottom: 2rem;
        }

        @include breakpoint(lg) {
            margin-bottom: 2.5rem;
        }

        img {
            margin: 0 1rem 0 0;
        }

        .action-link {
            padding: 0;
            line-height: 1;
        }

        span {
            display: block;
        }

        input[type='file'] {
            opacity: 0.0001;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }

    .buttons-wrapper {
        display: block;
        text-align: center;

        @include breakpoint(sm) {
            display: flex;
            align-items: center;
        }

        .button {
            display: block;
            margin: 0 auto 2rem;

            @include breakpoint(sm) {
                margin: 0;

                & + .link {
                    margin-left: 2rem;
                }
            }
        }

        &.wide {
            .button {
                width: 100%;

                @include breakpoint(sm) {
                    width: auto;
                }
            }
        }
    }
}

.add-profile {
    font-size: 1.625rem;
    line-height: 1.125;
    display: inline-block;
    position: relative;
    padding-left: 3rem;
    color: $dark;

    @include font(300);

    @include breakpoint(sm) {
        font-size: 1.875rem;
        padding-left: 3.5rem;
    }

    @include breakpoint(lg) {
        font-size: 2.375rem;
        padding-left: 4.5rem;
    }

    span {
        display: block;
        width: 2.25rem;
        height: 2.25rem;
        position: absolute;
        left: 0;
        top: -.25rem;
        color: $pink;

        &::before {
            font-size: 1.875rem;
            line-height: 1;

            @include breakpoint(sm) {
                font-size: 2.25rem;
            }

            @include breakpoint(lg) {
                font-size: 2.875rem;
            }
        }

        @include breakpoint(sm) {
            width: 2.5rem;
            height: 2.5rem;
            top: -.375rem;
        }

        @include breakpoint(lg) {
            width: 3rem;
            height: 3rem;
        }
    }
}
