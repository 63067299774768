.overlay {
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    will-change: auto;
    z-index: 15;

    @include opacity(.25s);

    &.active {
        opacity: .85;
        visibility: visible;
    }

    &.light {
        background-color: $white;
    }

    &.dark {
        background-color: $dark;
    }
}
