article {
    position: relative;
    display: block;

    &.single-page {

        [itemprop='description'] {
            p {
                display: block;
            }
        }
    }

    .inner-section {
        display: block;
        position: relative;
    }
}
