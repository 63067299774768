.cookies-notification {
    display: block;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: transparentize($dark, .05);
    color: $iron;
    font-size: .875rem;
    line-height: 1.375rem;
    z-index: 99999;
    padding: 1.25rem 0 1rem;
    backface-visibility: hidden;
    will-change: transform;
    transform: translateY(100%);
    transition: transform .75s ease-in-out;

    &.active {
        transform: translateY(0);
    }

    @include breakpoint(sm) {
        padding: 1.5rem 5vw 1rem;
        min-height: 7rem;
    }

    @include breakpoint(lg) {
        padding: 1.75rem 5vw 1rem;
    }

    @include breakpoint(xl) {
        padding: 2rem 5vw 1rem;
    }

    .inner {
        display: block;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 50vh;
        padding-right: .625rem;

        @include breakpoint(lg) {
            padding-bottom: 0;
            padding-right: 1.25rem;
        }

        .ios & {
            max-height: 15rem;

            @include breakpoint(md) {
                max-height: none;
            }
        }
    }

    .cookies-button {
        position: relative;
        display: inline-block;
        color: $white;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        cursor: pointer;

        &:before {
            line-height: 1.675rem;
        }
    }

    p, a, span, strong {
        font-size: .875rem;
        line-height: 1.375rem;
        color: $white;
    }

    p {
        margin-bottom: 1em;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    a {
        @include font(700);
    }

    .shrink {
        width: 3rem;

        @include breakpoint(lg) {
            width: 4rem;
        }
    }
}
