.accordion-container {
    position: relative;
    margin-bottom: 2rem;
}

.accordion {
    position: relative;
    display: block;
    border-bottom: 1px solid $iron;

    .question {
        position: relative;
        display: block;
        cursor: pointer;
        padding: 1.375rem 4rem 1.275rem .125rem;
        color: $pink;
        font-size: 1rem;
        line-height: 1.25em;

        @include font(600);

        @include breakpoint(sm) {
            font-size: 1.125rem;
        }

        @include breakpoint(lg) {
            font-size: 1.25rem;
            padding: 1.5rem 5rem 1.375rem .125rem;
        }

        @include breakpoint(xl) {
            font-size: 1.375rem;
        }

        &::before {
            content: '';
            width: .625rem;
            height: .625rem;
            display: block;
            position: absolute;
            top: 48%;
            right: .5rem;
            border-left: 2px solid $dark;
            border-bottom: 2px solid $dark;
            transition: all .35s ease;
            transform: translateY(-50%) rotate(-45deg);
        }
    }

    &.active {
        .question {
            border-bottom-color: $white;

            &::before {
                top: 52%;
                transform: translateY(-50%) rotate(135deg);
            }
        }
    }

    .answer {
        position: relative;
        display: none;
        padding: 1.375rem 0 1.275rem;
        font-size: 1rem;
        line-height: 1.25em;
        color: $gray;

        @include font(400);

        @include breakpoint(sm) {
            font-size: 1.125rem;
        }

        @include breakpoint(lg) {
            font-size: 1.25rem;
            padding: 1.5rem 0 1.375rem;
        }

        @include breakpoint(xl) {
            font-size: 1.375rem;
        }

        b,
        strong {
            color:  $dark;

            @include font(700);
        }

        p,
        span {
            color: $gray;
        }
    }
}
