ol,
ul {
    list-style: none;
    padding: 0;
    margin-left: 0;

    &.list {

        li {
            position: relative;
            display: block;
            margin-bottom: 1rem;
            padding-left:  1.625rem;

            @include breakpoint(sm) {
                padding-left:  2rem;
            }

            @include breakpoint(lg) {
                padding-left:  2.375rem;
            }

            &::before {
                content: '';
                color: $dark;
                display: block;
                font-size: 1rem;
                line-height: 1.25;
                position: absolute;
                left: 0;
                top: 0;
                text-transform: uppercase;

                @include font(700);

                @include breakpoint(sm) {
                    font-size: 1.25rem;
                }

                @include breakpoint(lg) {
                    font-size: 1.5rem;
                }
            }

            @include breakpoint(sm) {
                margin-bottom: 1.25rem;
            }

            @include breakpoint(lg) {
                margin-bottom: 1.5rem;
            }

            & > ul,
            & > ol {
                padding-top: 1rem;

                @include breakpoint(sm) {
                    padding-top: 1.25rem;
                }

                @include breakpoint(lg) {
                    padding-top: 1.5rem;
                }
            }
        }
    }
}

ul {
    &.list {
        & > li {
            &:nth-child(1) {
                &::before {
                    content: 'a.';
                }
            }
            &:nth-child(2) {
                &::before {
                    content: 'b.';
                }
            }
            &:nth-child(3) {
                &::before {
                    content: 'c.';
                }
            }
            &:nth-child(4) {
                &::before {
                    content: 'd.';
                }
            }
            &:nth-child(5) {
                &::before {
                    content: 'e.';
                }
            }
            &:nth-child(6) {
                &::before {
                    content: 'f.';
                }
            }
            &:nth-child(7) {
                &::before {
                    content: 'g.';
                }
            }
            &:nth-child(8) {
                &::before {
                    content: 'h.';
                }
            }
            &:nth-child(9) {
                &::before {
                    content: 'i.';
                }
            }
            &:nth-child(10) {
                &::before {
                    content: 'j.';
                }
            }
            &:nth-child(11) {
                &::before {
                    content: 'k.';
                }
            }
            &:nth-child(12) {
                &::before {
                    content: 'l.';
                }
            }
            &:nth-child(13) {
                &::before {
                    content: 'm.';
                }
            }
            &:nth-child(14) {
                &::before {
                    content: 'n.';
                }
            }
            &:nth-child(15) {
                &::before {
                    content: '0.';
                }
            }
        }
    }

    &.dots {
        li {
            position: relative;
            display: block;
            margin-bottom: 1.375em;
            padding-left:  1.625rem;
            color: $gray;

            @include breakpoint(sm) {
                padding-left:  2rem;
            }

            @include breakpoint(lg) {
                padding-left:  2.375rem;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: .5em;
                width: .5rem;
                height: .5rem;
                border-radius: 50%;
                background-color: $dark;
            }
        }
    }
}

ol {
    &.list {
        & > li {
            &:nth-child(1) {
                &::before {
                    content: '1.';
                }
            }
            &:nth-child(2) {
                &::before {
                    content: '2.';
                }
            }
            &:nth-child(3) {
                &::before {
                    content: '3.';
                }
            }
            &:nth-child(4) {
                &::before {
                    content: '4.';
                }
            }
            &:nth-child(5) {
                &::before {
                    content: '5.';
                }
            }
            &:nth-child(6) {
                &::before {
                    content: '6.';
                }
            }
            &:nth-child(7) {
                &::before {
                    content: '7.';
                }
            }
            &:nth-child(8) {
                &::before {
                    content: '8.';
                }
            }
            &:nth-child(9) {
                &::before {
                    content: '9.';
                }
            }
            &:nth-child(10) {
                &::before {
                    content: '10.';
                }
            }
            &:nth-child(11) {
                &::before {
                    content: '11.';
                }
            }
            &:nth-child(12) {
                &::before {
                    content: '12.';
                }
            }
            &:nth-child(13) {
                &::before {
                    content: '13.';
                }
            }
            &:nth-child(14) {
                &::before {
                    content: '14.';
                }
            }
            &:nth-child(15) {
                &::before {
                    content: '15.';
                }
            }
        }
    }
}
