.field {
    position: relative;
    display: block;
    margin-bottom: 1.5rem;
    z-index: 1;

    label {
        display: block;
        font-size: 1rem;
        line-height: 1.25;
        color: $dark;
        margin-bottom: .75rem;

        @include font(400);

        @include breakpoint(sm) {
            font-size: 1.125rem;
        }

        @include breakpoint(lg) {
            font-size: 1.375rem;
        }
    }

    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='number'],
    input[type='date'] {
        display: block;
        width: 100%;
        border: 1px solid darken($porcelain, 5%);
        border-radius: 0;
        color: $dark;
        font-size: 1rem;
        height: 42px;
        line-height: 1.25;
        padding: .75rem 1.25rem .5rem;
        box-shadow: none;

        @include font(400);

        &::placeholder {
            color: $dark;

            @include font(300);
        }

        &:focus {
            border: 1px solid $gray;
        }

        & + input {
            margin-top: .75rem;
        }
    }

    textarea {
        display: block;
        width: 100%;
        border: 1px solid darken($porcelain, 5%);
        border-radius: 0;
        color: $dark;
        font-size: 1rem;
        line-height: 1;
        padding: 1rem 1.25rem;
        box-shadow: none;
        min-height: 100px;

        @include font(400);

        &::placeholder {
            color: $dark;

            @include font(300);
        }
    }

    small {
        display: block;
        font-size: .75rem;
        line-height: 1.25;
        color: $gray;

        @include font(300);
    }

    p {
        margin-bottom: 0;
    }

    // Checkbox
    &.checkbox {
        display: block;

        input[type='checkbox'] {
            position: absolute;
            width: 1px;
            height: 1px;
            border: 0;
            top: 0;
            left: 0;
            opacity: 0;

            & + label {
                position: relative;
                display: block;
                font-size: 1rem;
                line-height: 1.5;
                cursor: pointer;
                padding-left: 40px;
                color: $dark;

                @include font(400);

                &::before {
                    content: '';
                    width: 24px;
                    height: 24px;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $white;
                    border: 2px solid $dark;
                    border-radius: 6px;
                    transition: all .25s ease;
                }

                &::after {
                    content: '';
                    width: 12px;
                    height: 8px;
                    display: block;
                    border-left: 2px solid $white;
                    border-bottom: 2px solid $white;
                    position: absolute;
                    top: .375rem;
                    left: .375rem;
                    opacity: 0;
                    transform: rotate(-45deg) scale(1.5);
                    transition: all .25s ease;
                }

                span {
                    display: block;
                    position: relative;
                    color: $grey;
                }

                ul {
                    display: block;
                    position: relative;
                    padding: 1.25rem 0 0 1rem;

                    @include breakpoint(sm) {
                        padding: 1.25rem 0 0 1.375rem;
                    }

                    @include breakpoint(lg) {
                        padding: 1.25rem 0 0 2rem;
                    }
                }

                li {
                    display: block;
                    position: relative;
                    padding-left: 1.25rem;
                    margin-bottom: 1.25rem;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    &::before {
                        content: '';
                        width: 6px;
                        height: 6px;
                        display: block;
                        position: absolute;
                        top: 6px;
                        left: 0;
                        background-color: $dark;
                        border-radius: 50%;
                    }
                }
            }

            &:checked {
                & + label {
                    &::before {
                        background-color: $dark;
                    }
                    &::after {
                        opacity: 1;
                        transform: rotate(-45deg) scale(1);
                    }
                }
            }
        }

        &.invalid {
            input[type='checkbox'] {
                & + label {
                    &::before {
                        border-color: $invalid;
                    }
                }
                &:checked {
                    & + label {
                        &::before {
                            border-color: $dark;
                        }
                    }
                }
            }
        }
    }

    // Radio
    &.radio {
        display: block;

        input[type='radio'] {
            position: absolute;
            width: 1px;
            height: 1px;
            border: 0;
            top: 0;
            left: 0;
            opacity: 0;

            & + label {
                position: relative;
                display: block;
                font-size: 1rem;
                line-height: 1.5;
                cursor: pointer;
                padding-left: 40px;
                color: $dark;

                @include font(400);

                &::before {
                    content: '';
                    width: 24px;
                    height: 24px;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: $white;
                    border: 2px solid $dark;
                    border-radius: 50%;
                    transition: all .25s ease;
                }

                &::after {
                    content: '';
                    width: 14px;
                    height: 14px;
                    display: block;
                    background-color: $dark;
                    position: absolute;
                    top: .3125rem;
                    left: .3125rem;
                    opacity: 0;
                    border-radius: 50%;
                    transform: scale(1.5);
                    transition: all .25s ease;
                }
            }

            &:checked {
                & + label {
                    &::after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }
        }

        &.invalid {
            input[type='radio'] {
                & + label {
                    &::before {
                        border-color: $invalid;
                    }
                }
                &:checked {
                    & + label {
                        &::before {
                            border-color: $dark;
                        }
                    }
                }
            }
        }
    }

    // Switch
    &.switch {
        display: block;

        input[type='checkbox'] {
            position: absolute;
            width: 1px;
            height: 1px;
            border: 0;
            top: 0;
            left: 0;
            opacity: 0;

            & + label {
                position: relative;
                display: block;
                font-size: 1rem;
                line-height: 1.5;
                cursor: pointer;
                padding-left: 50px;
                color: $dark;

                @include font(400);

                &::before {
                    content: '';
                    width: 24px;
                    height: 24px;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: lighten($iron, 6%);
                    border: 2px solid $dark;
                    border-radius: 50%;
                    transition: all .35s ease;
                    transform: translateX(0);
                    z-index: 1;
                }

                &::after {
                    content: '';
                    width: 38px;
                    height: 12px;
                    display: block;
                    border: 2px solid $dark;
                    border-radius: 6px;
                    position: absolute;
                    top: .375rem;
                    left: 0;
                }
            }

            &:checked {
                & + label {
                    &::before {
                        background-color: $pink;
                        border-color: $pink;
                        transform: translateX(14px);
                    }
                }
            }
        }

        &.invalid {
            input[type='checkbox'] {
                & + label {
                    &::before {
                        border-color: $invalid;
                        background-color: $white;
                    }
                    &::after {
                        border-color: $invalid;
                    }
                }
                &:checked {
                    & + label {
                        &::before {
                            border-color: $pink;
                            background-color: $pink;
                        }
                        &::after {
                            border-color: $dark;
                        }
                    }
                }
            }
        }
    }

    &.valid {
        input[type='text'],
        input[type='email'],
        input[type='password'],
        input[type='number'],
        input[type='date'],
        textarea {
            border-color: $valid;
        }
    }

    &.invalid {
        input[type='text'],
        input[type='email'],
        input[type='password'],
        input[type='number'],
        input[type='date'],
        textarea {
            border-color: $invalid;
        }
    }

    &.combo-field {
        width: 100%;

        @include breakpoint(sm) {
            display: flex;
        }

        input {
            margin-bottom: 1.5rem;

            @include breakpoint(sm) {
                margin-bottom: 0;
                width: calc(100% - 180px);
            }
        }

        button,
        .button {
            width: 100%;

            @include breakpoint(sm) {
                width: 180px;
            }
        }
    }
}

.form-container {
    .divider {
        display: block;
        text-align: center;
        position: relative;
        line-height: 1rem;
        height: 1rem;
        margin: 0 auto 1.5rem;
        z-index: 1;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: $iron;
            position: absolute;
            top: .375rem;
            left: 0;
            z-index: -1;
        }

        span {
            display: inline-block;
            vertical-align: top;
            color: $dark;
            background-color: $white;
            font-size: 1rem;
            line-height: 1;
            padding: 0 1rem;
        }
    }

    .legend {
        display: block;
        text-align: right;
        font-size: .75rem;
        line-height: 1;
        color: lighten($gray, 13%);
        position: relative;

        @include font(300);
    }

    p {
        font-size: 1rem;
        color: $dark;

        @include font(300);

        .link {
            display: inline;
            font-size: .75rem;
        }
    }

    .sub-title {
        display: block;
        font-size: 1.125rem;

        @include breakpoint(sm) {
            font-size: 1.25rem;
        }

        @include breakpoint(lg) {
            font-size: 1.375rem;
        }
    }

    .buttons-wrapper {
        .button {
            & + .link {
                margin-left: 2rem;
            }
        }

        &.wide {
            text-align: center;

            @include breakpoint(sm) {
                text-align: left;
            }

            .button {
                width: 100%;
                display: block;
                margin: 0 auto 2rem;

                @include breakpoint(sm) {
                    display: inline-block;
                    width: auto;
                    margin: 0;
                }
            }

            .link {
                margin-left: 0;

                @include breakpoint(sm) {
                    margin-left: 2rem;
                }
            }
        }
    }
}
