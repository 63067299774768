.offer-item {
    display: block;
    position: relative;
    margin-bottom: 2.75rem;

    @include breakpoint(sm) {
        margin-bottom: 3.5rem;
    }

    @include breakpoint(lg) {
        margin-bottom: 5rem;
    }

    .image-container {
        display: block;
        height: 0;
        overflow: hidden;
        padding-bottom: 95%;
        position: relative;
        width: calc(100% + 1.5rem);
        margin-left: -.75rem;
        margin-bottom: 1.375rem;

        @include breakpoint(sm) {
            width: 100%;
            margin-left: 0;
            margin-bottom: 2.375rem;
        }

        &:hover {
            .item-overlay {
                opacity: .85;
            }

            .available-social,
            .button,
            strong,
            p {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }

            .available-social {
                transition-delay: 0.1s;
            }
            strong {
                transition-delay: 0.15s;
            }
            p {
                transition-delay: 0.2s;
            }
            .button {
                transition-delay: 0.25s;
            }
        }
    }

    .item-overlay {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        will-change: auto;
        transition: opacity .25s ease-in-out;

        @include gradient-lr($pink-salmon, $salmon);
    }

    .offer-desc {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 2.25rem 2rem 2rem;
        text-align: center;

        @include breakpoint(sm) {
            padding: 2.25rem 1rem 2rem;
        }

        @include breakpoint(xl) {
            padding: 2.5rem 2rem 2rem;
        }

        strong {
            font-size: 1.25rem;
            text-transform: uppercase;
            opacity: 0;
            will-change: auto;
            transform: translate3d(0, .5rem, 0);
            transition: all .4s ease-in-out;

            @include title(900);

            @include breakpoint(sm) {
                font-size: 1.5rem;
            }

            @include breakpoint(xl) {
                font-size: 2.375rem;
            }
        }

        p {
            font-size: .875rem;
            line-height: 1.25;
            margin-bottom: 0;
            opacity: 0;
            overflow: hidden;
            will-change: auto;
            transform: translate3d(0, .5rem, 0);
            transition: all .4s ease-in-out;

            @include font(600);

            @include breakpoint(sm) {
                font-size: 1rem;
            }

            @include breakpoint(lg) {
                max-height: 5rem;
            }

            @include breakpoint(xl) {
                font-size: 1.375rem;
                max-height: 6.75rem;
            }
        }

        .button {
            margin: 0 auto;
            opacity: 0;
            will-change: auto;
            transform: translate3d(0, .5rem, 0);
            transition: all .4s ease-in-out;
        }
    }

    .entry-title {
        display: block;
        font-size: 1.375rem;
        line-height: 1.25;
        margin-bottom: 0;
        color: $gray;

        @include font(400);
    }

    .offer-expire-date {
        display: block;
        color: $dark;
        font-size: .75rem;
        margin-bottom: .625rem;
        text-transform: uppercase;

        @include font(700);
    }

    &.upcoming {
        .offer-desc {
            text-align: left;
            color: $white;
        }

        .available-social {
            justify-content: left;
        }

        .item-overlay {
            opacity: .65;
            @include gradient-lr3($amethyst, $lipstick, $pink);
        }

        .available-social,
        .button,
        strong,
        p {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    .badge {
        display: block;
        position: absolute;

        &.type-special {
            top: 1rem;
            right: 1rem;
        }

        &.type-ended {
            top: 1rem;
            left: 50%;
            transform: translateX(-50%);
        }

        &.type-new-offer {
            top: 1rem;
            right: 1rem;
        }

        &.type-last-minute {
            top: 1rem;
            right: 1rem;
        }
    }
}
