.banner {
    .upcoming {

        @include breakpoint(up-to-sm) {
            position: relative;
            width: calc(100% + 1.5rem);
            margin-left: -.75rem;
        }

        .offer-desc {
            text-align: left;
            color: $white;
            display: block;
            position: relative;
            padding: 2.25rem 2rem;

            @include breakpoint(sm) {
                padding: 2.75rem 2.375rem;
            }

            @include breakpoint(lg) {
                padding: 3.5rem 3rem;
            }
        }

        .available-social {
            justify-content: left;
        }

        .item-overlay {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            will-change: auto;
            opacity: .65;
            transition: opacity .25s ease-in-out;

            @include gradient-lr3($amethyst, $lipstick, $pink);
        }

        strong,
        p {
            display: block;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }

        p {
            font-size: 1rem;
            margin-bottom: .75em;

            @include font(300);

            @include breakpoint(sm) {
                font-size: 1.125rem;
                margin-bottom: 1em;
            }

            @include breakpoint(lg) {
                font-size: 1.375rem;
                margin-bottom: 1.25em;
            }
        }

        strong {
            margin-bottom: .375em;
            font-size: 1.625rem;

            @include title(900);

            @include breakpoint(sm) {
                font-size: 1.9375rem;
            }

            @include breakpoint(lg) {
                font-size: 2.375rem;
            }
        }

        .button {
            line-height: 1.5rem;

            span {
                font-size: 1rem;
                height: 1rem;
                position: relative;
                display: inline-block;
                vertical-align: top;
                margin-right: .25em;

                &::before {
                    position: relative;
                    font-size: 1.5rem;
                    top: -2px;
                }
            }
        }

        &:hover {
            .item-overlay {
                opacity: .85;
            }
        }
    }
}
