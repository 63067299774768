.hamburger-menu {
    display: block;
    width: 24px;
    height: 20px;
    border-radius: 0;
    position: relative;
    z-index: 20;
    cursor: pointer;

    &::before {
        content: '';
        width: 34px;
        height: 30px;
        display: block;
        position: absolute;
        top: -5px;
        left: -5px;
    }

    &:focus,
    &:active {
        outline: none;
    }

    span {
        display: block;
        width: 100%;
        height: 2px;
        left: 0;
        position: absolute;
        opacity: 1;
        border-radius: 3px;
        pointer-events: none;
        background-color: $dark;
        transform: translate3d(0, 0, 0) rotate(0);
        transition: all .3s ease-in-out;

        &:nth-child(1) {
            top: 1px;
        }
        &:nth-child(2) {
            top: 9px;
        }
        &:nth-child(3) {
            top: 17px;
        }
    }

    &.active {
        span {
            &:nth-child(1) {
                transform: translate3d(0, 8px, 0) rotate(225deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                transform: translate3d(0, -8px, 0) rotate(-225deg);
            }
        }
    }
}
