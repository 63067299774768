.off-topic {
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    background-color: $white;
    padding: 1.25rem 4rem 1.125rem 3rem;
    border-radius: 1.25rem 0 1.25rem 1.25rem;
    box-shadow: 0 0 1rem transparentize($dark, .9);
    margin-bottom: 1.5rem;

    @include breakpoint(sm) {
        padding: 1.5rem 4.5rem 1.5rem 3rem;
        border-radius: 1.75rem 0 1.75rem 1.75rem;
        margin-bottom: 2.5rem;
    }

    @include breakpoint(lg) {
        display: inline-block;
        padding: 1.75rem 5.5rem 1.5rem 4rem;
        border-radius: 2.5rem 0 2.5rem 2.5rem;
        margin-bottom: 3.5rem;
        max-width: 26.75rem;
    }

    & > .icon {
        font-size: 1.5rem;
        position: absolute;
        display: block;
        top: .75rem;
        left: .75rem;

        @include breakpoint(sm) {
            top: 1rem;
            left: 1.5rem;
        }

        @include breakpoint(lg) {
            top: 1.25rem;
            left: 2rem;
        }
    }

    .company-logo {
        display: block;
        position: absolute;
        width: 2.625rem;
        height: 2.625rem;
        top: .75rem;
        right: .75rem;

        @include breakpoint(sm) {
            top: 1rem;
            right: 1rem;
        }

        @include breakpoint(lg) {
            top: 1.25rem;
            right: 1.25rem;
        }
    }

    .available-social {
        opacity: 1;
        transform: none;
        justify-content: left;

        li {
            padding: 0;
            margin: 0 .5rem 0 0;
        }

        span {
            color: $dark;
            display: block;

            &:before {
                font-size: 1.25rem;

                @include breakpoint(sm) {
                    font-size: 1.375rem;
                }

                @include breakpoint(lg) {
                    font-size: 1.625rem;
                }
            }
        }
    }

    .label {
        text-transform: uppercase;
        font-size: .75rem;
        line-height: 1;
        display: block;
        color: $dark;
        margin-bottom: 1em;

        @include font(700);
    }

    .inner {
        font-size: 1rem;
        display: block;
        position: relative;
        line-height: 1.125;

        @include breakpoint(sm) {
            font-size: 1.125rem;
        }

        @include breakpoint(lg) {
            font-size: 1.375rem;
        }

        & > span {
            display: block;
            line-height: 1.25;
            color: $grey;

            @include font(400);

            @include breakpoint(sm) {
                font-size: 1.125rem;
            }

            @include breakpoint(lg) {
                font-size: 1.375rem;
            }
        }
    }
}
