.callout {
    position: relative;
    display: block;
    margin-bottom: 1.5rem;
    padding: 2rem 1.25rem;
    color: $white;
    transition: all .25s ease-in-out;

    @include breakpoint(sm) {
        margin-bottom: 1.75rem;
        padding: 2.75rem 2rem;
    }

    @include breakpoint(lg) {
        margin-bottom: 2rem;
        padding: 3.5rem 2.75rem;
    }

    &.small {
        padding: 1rem .25rem;

        @include breakpoint(sm) {
            padding: 1.5rem .75rem;
        }

        @include breakpoint(lg) {
            padding: 2rem 1.25rem;
        }
    }

    &.large {
        padding: 3rem 2.25rem;

        @include breakpoint(sm) {
            padding: 4.5rem 3.75rem;
        }

        @include breakpoint(lg) {
            padding: 6rem 5.25rem;
        }
    }

    &.wide {
        width: 100vw;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        padding-left: 0;
        padding-right: 0;

        .inner {
            display: block;
            position: relative;
            padding-left: 2rem;
            padding-right: 2rem;

            @include breakpoint(sm) {
                padding-left: 2.75rem;
                padding-right: 2.75rem;
            }

            @include breakpoint(lg) {
                padding-left: 3.5rem;
                padding-right: 3.5rem;
            }
        }
    }

    .row {
        max-width: 85rem !important;
        margin-right: auto !important;
        margin-left: auto !important;
    }

    &.type-1,
    .type-1 {
        @include gradient-lr($black, $tundora);
    }

    &.type-2,
    .type-2 {
        @include gradient-lr($violet, $plum);
    }

    &.type-3,
    .type-3 {
        @include gradient-lr($jaffa, $orange);
    }

    &.type-4,
    .type-4 {
        @include gradient-lr($pink-salmon, $salmon);
    }

    &.type-5,
    .type-5 {
        background-color: $white;
        color: $dark;
        border: 1px solid $iron;

        p,
        span,
        strong,
        b,
        .title {
            color: $dark;
        }
    }

    .overlay {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .8;
        visibility: visible;
        z-index: -1;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    p,
    span,
    strong,
    b,
    .title {
        color: $white;
    }
}
