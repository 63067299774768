.mobile-menu-container {
    display: block;
    position: fixed;
    height: 100%;
    max-height: 100%;
    width: 16rem;
    left: -16rem;
    top: 0;
    background-color: $pink;
    color: $white;
    padding: 0;
    z-index: 20;
    transform: translateX(0);
    transition: all .25s ease-in-out;

    @include breakpoint(xs) {
        width: 20rem;
        left: -20rem;
    }

    @include breakpoint(lg) {
        transform: translateX(0);
    }

    .menu-open & {
        transform: translateX(100%);

        @include breakpoint(lg) {
            transform: translateX(0);
        }
    }

    .inner-container {
        padding: 1.5rem 1.25rem;
        display: block;
        height: 100%;
        max-height: 100%;
        position: relative;
        overflow: auto;

        @include breakpoint(xs) {
            padding: 2rem;
        }

        .avatar {
            display: block;
            width: 5rem;
            height: 5rem;
            margin: 0 auto 1rem;
            border-radius: 50%;
        }

        .active-profile {
            display: block;
            text-transform: uppercase;
            font-size: .9375rem;
            text-align: center;
            margin-bottom: 1.25rem;
            color: $white;

            @include title(900);
        }

        .mobile-menu {
            display: block;
            position: relative;
            margin-bottom: 3rem;

            li {
                border-bottom: 1px solid darken($pink, 5%);
                display: block;
                position: relative;
            }

            a {
                color: $white;
                display: block;
                padding: .5rem;
                font-size: .975rem;

                @include font(400);
            }
        }

        .social {
            display: block;
            position: relative;

            li {
                display: inline-block;
                vertical-align: top;
                margin-right: .75rem;
            }

            span,
            a {
                display: block;
                color: $white;
                line-height: 1.375rem;
            }

            span {
                font-size: .9375rem;

                @include font(400);
            }

            a {
                font-size: 1.375rem;
            }
        }
    }
}
