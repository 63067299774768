.product-page {

    header,
    main {
        background: linear-gradient(to right, transparentize($pink-salmon, .9) 0%, transparentize($salmon, .9) 100%);
    }

    main {
        padding-bottom: 0;
    }

    .heading {
        display: block;
        font-size: 1.625rem;
        line-height: 1.125em;
        margin-bottom: .75rem;
        color: $dark;

        @include title(300);

        @include breakpoint(sm) {
            font-size: 1.875rem;
        }

        @include breakpoint(lg) {
            font-size: 2.375rem;
        }

        &.bold {
            @include title(900);
        }

        .icon {
            display: inline-block;
            vertical-align: top;
            color: $pink;
            margin-right: .125rem;
        }
    }

    .labeled-heading {
        display: inline-block;
        vertical-align: top;
        position: relative;
        padding-right: 2rem;
        margin-bottom: 1.5rem;

        & + .labeled-heading {
            padding: 0 2rem;

            &::before {
                content: '';
                width: 1px;
                height: 100%;
                display: block;
                background-color: $iron;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    .product-gallery {
        position: relative;
        display: block;
        width: 100vw;
        margin-left: -6vw;
        margin-bottom: 1rem;

        @include breakpoint(sm) {
            margin: 0 0 2rem;
            width: 100%;
        }

        .featured-image {
            display: block;
            position: relative;
            margin: 0 .25rem .5rem;

            @include breakpoint(lg) {
                margin: 0 .75rem 1.25rem;
            }
        }
    }

    .description {
        position: relative;
        display: block;
        padding: 0;

        @include breakpoint(sm) {
            padding: 0 1rem;
        }

        @include breakpoint(lg) {
            padding: 0 2.5rem;
        }

        .expires {
            display: block;
            position: relative;
            margin-bottom: 1.5rem;

            @include breakpoint(sm) {
                margin-bottom: 2.5rem;
            }

            @include breakpoint(lg) {
                margin-bottom: 3.5rem;
            }
        }

        .label {
            font-size: .75rem;
            color: $dark;
            display: block;
            line-height: 1;
            margin-bottom: 1em;
            text-transform: uppercase;

            @include font(700);
        }

        p {
            display: block;
            font-size: 1rem;
            line-height: 1.75;
            margin-bottom: 1.5rem;

            @include font(700);

            @include breakpoint(sm) {
                font-size: 1.0625rem;
                line-height: 2;
                margin-bottom: 2.5rem;
            }

            @include breakpoint(lg) {
                font-size: 1.125rem;
                line-height: 2.25;
                margin-bottom: 3.5rem;
            }
        }

        .button {
            @include breakpoint(up-to-sm) {
                display: block;
                width: 100%;
            }
        }
    }

    .rules {
        display: block;
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 2px;
            height: 100%;
            position: absolute;
            left: .9375rem;
            top: 0;
            z-index: -1;
            background-color: transparentize($black, .8);

            @include breakpoint(sm) {
                left: 1.0625rem;
            }

            @include breakpoint(lg) {
                left: 1.25rem;
            }
        }

        .heading {
            position: relative;
            padding-left: 3rem;

            @include breakpoint(sm) {
                padding-left: 3.25rem;
            }

            @include breakpoint(lg) {
                padding-left: 3.75rem;
            }

            span {
                color: $pink;
                font-size: 1.625rem;
                width: 2rem;
                line-height: 2rem;
                display: block;
                text-align: center;
                position: absolute;
                left: 0;
                top: 0;

                @include font(300);

                @include breakpoint(sm) {
                    font-size: 1.875rem;
                    width: 2.25rem;
                    line-height: 2.25rem;
                }

                @include breakpoint(lg) {
                    font-size: 2.375rem;
                    width: 2.625rem;
                    line-height: 2.625rem;
                }

                &::before {
                    content: '';
                    background-color: $white;
                    width: 2rem;
                    height: 2rem;
                    display: block;
                    position: absolute;
                    border: 2px solid $pink;
                    border-radius: 50%;
                    top: -.25rem;
                    left: 0;
                    z-index: -1;

                    @include breakpoint(sm) {
                        width: 2.25rem;
                        height: 2.25rem;
                    }

                    @include breakpoint(lg) {
                        width: 2.625rem;
                        height: 2.625rem;
                    }
                }
            }
        }

        & > p {
            display: block;
            padding-left: 3rem;

            @include breakpoint(sm) {
                padding-left: 3.25rem;
            }

            @include breakpoint(lg) {
                padding-left: 3.75rem;
            }
        }

        ul {
            position: relative;
            display: block;

            li {
                display: block;
                position: relative;
                padding-left: 3rem;

                &::before {
                    content: '';
                    width: 6px;
                    height: 6px;
                    display: block;
                    position: absolute;
                    top: .3125rem;
                    left: .8125rem;
                    background-color: $dark;
                    border-radius: 50%;

                    @include breakpoint(sm) {
                        top: .4375rem;
                        left: .9375rem;
                    }

                    @include breakpoint(lg) {
                        width: 8px;
                        height: 8px;
                        top: .4375rem;
                        left: 1.0625rem;
                    }
                }

                @include breakpoint(sm) {
                    padding-left: 3.25rem;
                }

                @include breakpoint(lg) {
                    padding-left: 3.75rem;
                }
            }
        }
    }
}
