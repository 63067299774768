footer {
    position: relative;
    display: block;
}

// Footer menu
.footer-menu {
    display: block;
    position: relative;
    background-color: $porcelain;
    padding: 2.75rem 2vw 2.25rem;

    .columns {
        &:nth-child(4) {
            ul {
                @include breakpoint(sm) {
                    margin-bottom: 0;
                }
            }
        }
        &:nth-child(5) {
            ul {
                margin-bottom: 0;
            }
        }
    }

    .column-title {
        display: block;
        color: $pink;
        font-size: 1.375rem;
        line-height: 1em;
        margin-bottom: .875em;

        @include font(600);

        @include breakpoint(sm) {
            margin-bottom: 1.125em;
        }

        @include breakpoint(sm) {
            margin-bottom: 1.375em;
        }
    }

    ul[role='menu'] {
        position: relative;
        margin-bottom: 2rem;

        li {
            display: block;
            position: relative;
            margin-bottom: .5rem;

            @include breakpoint(sm) {
                margin-bottom: .625rem;
            }

            @include breakpoint(sm) {
                margin-bottom: .75rem;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    ul[role='list'] {
        position: relative;
        margin-bottom: 2rem;

        li {
            display: inline-block;
            vertical-align: top;
            position: relative;
            height: 1.75rem;
            line-height: 1.75rem;
            margin-right: .5rem;
        }

        .icon {
            display: block;
            width: 28px;
            height: 28px;
            position: relative;
            font-size: 1.75rem;
            line-height: 1.75rem;
        }
    }

    a {
        text-transform: uppercase;
        font-size: .875rem;
        line-height: 1em;
        color: $dark;
        margin: 0;

        @include font(700);
    }

    span {
        text-transform: uppercase;
        display: inline-block;
        color: $dark;
        font-size: .875rem;
        line-height: 1.25em;
        margin: 0;

        @include font(400);
    }
}

// Brands carousel
.brands {
    position: relative;
    padding: 1.25rem 0;

    @include breakpoint(sm) {
        padding: 1.75rem 0;
    }

    @include breakpoint(lg) {
        padding: 2.5rem 0;
    }

    .swiper-container {
        position: relative;
        display: block;
        margin: 0 auto;
        width: calc(100% - 3rem);

        @include breakpoint(sm) {
            width: calc(100% - 4rem);
        }

        @include breakpoint(lg) {
            width: calc(100% - 5rem);
        }

        .swiper-slide {
            display: block;
            padding: 0;

            @include breakpoint(sm) {
                padding: .125rem;
            }

            @include breakpoint(lg) {
                padding: .5rem;
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 1.5rem;
        height: 1.5rem;
        display: block;
        position: absolute;
        background: none;
        margin-top: -.75rem;

        &::before {
            font-size: 1.5rem;
            line-height: 1.5rem;
            height: 1.5rem;
            width: 1.5rem;
            display: block;
        }
    }
}

// Copyright
.copyright {
    position: relative;
    display: block;
    background: $dark;
    padding: .9375rem 0 .8125rem;
    line-height: 1em;

    @include breakpoint(sm) {
        padding: 1.5rem 0 1.25rem;
    }

    @include breakpoint(lg) {
        padding: 2.25rem 0 2rem;
    }

    p,
    a {
        display: inline-block;
        position: relative;
        color: $iron;
        font-size: 1rem;
        line-height: 1em;
        margin: 0;

        @include font(300);
    }
}
