.available-social {
    text-align: center;
    display: flex;
    justify-content: center;
    opacity: 0;
    transform: translate3d(0, .5rem, 0);
    will-change: auto;
    transition: all .4s ease-in-out;

    li {
        display: inline-block;
        vertical-align: top;
        padding: 0 .25rem;

        span {
            display: block;
            width: 1.75rem;
            height: 1.75rem;
            font-size: 1.75rem;
            line-height: 1.75rem;
        }
    }
}
