// Colors
$jaffa:         #f78c1c;
$orange:        #ff5800;
$coral:         #ff5553;
$pink-salmon:   #ff7eb3;
$salmon:        #ff758c;
$pink:          #ff004f;
$lipstick:      #d41872;
$amethyst:      #a445b2;
$violet:        #7448ff;
$plum:          #583787;
$facebook:      #4267b2;
$forest:        #008754;
$pippin:        #ffe0df;

$salmon-bg:     #fef2f7;
$amour:         #fefbfc;

$valid:         #66c45c;
$invalid:       #f00;

// Shades of Grey
$white:         #fff;
$porcelain:     #f4f7f6;
$iron:          #d6d6d6;
$gray:          #767676;
$grey:          #767676;
$tundora:       #464646;
$dark:          #2d2926;
$black:         #000;

// Breakpoints
$xs:            480px;
$sm:            640px;
$md:            768px;
$lg:            1024px;
$xl:            1280px;

// Constants
$font-path:     '../fonts';

// Offsets
$offset:        2vw;

// Animation settings ([data-view])
$animation-delay-list: (
    '0':    .1s,
    '1':    .15s,
    '2':    .2s,
    '3':    .25s,
    '4':    .3s,
    '5':    .35s,
    '6':    .4s
);
$animation-direction-list: (
    'to-top':       (0, 50px, 0),
    'to-left':      (50px, 0, 0),
    'to-right':     (-50px, 0, 0),
    'to-bottom':    (0, -50px, 0)
);
